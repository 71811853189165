.lineChart {
    position: relative; 
    width: 100%; 
    padding: 0;
}
.svg {
    /* background: #f0f0f0; */
    fill: none;
    stroke: #33C7FF;
    display: block;
    stroke-width: 5px;

    height: 100%; 
    width: 100%; 
    /* border-left: 1px solid black;
    border-bottom: 1px solid black; */
}
.pathText {
    font-family: Arial, Helvetica, sans-serif;
    fill: #c0c0c0;
    stroke: #c0c0c0;
    stroke-width: 0.5px;
}
/* #335C82, #62A99F, #608963, #B2B050, #D97831, #A5284C */
/* #E94D27, #393DB1, #2BAAA9, #C84297, #9AAB2E, #D32D39, #483C88 */

.pathOriginal {
    stroke: #015baf !important;
    stroke-width: .5px;
    fill: none;
}
.legendOriginal  {
    display: block;
    width: 10px;
    height: 2px;
    margin-right: 5px;
    background-color: #015baf !important;
}


.pathLeft0 {
    stroke: #015baf !important;
    /* stroke: #fc3557 !important; */
    stroke-width: 6px;
    fill: none;
    /* fill: #015baf;
    fill-opacity: .10; */
}
.pathLeftFill0 {
    fill: #015baf;
    fill-opacity: .10;
}
.legendLeft0 {
    display: block;
    width: 10px;
    height: 5px;
    margin-right: 5px;
    background-color: #015baf !important;
}

.pathLeft1 {
    stroke: #019c4a !important;
    stroke-width: 5px;
    fill: none;
    /* fill: #393DB1;
    fill-opacity: .15; */
}
.legendLeft1 {
    display: block;
    width: 10px;
    height: 4px;
    margin-right: 5px;
    background-color: #019c4a !important;
}
.pathLeft2 {
    stroke: #fc3557 !important;
    /*stroke: #ff8efc !important;*/
    stroke-width: 4px;
    /* stroke-dasharray: 10; */
    fill: none;
    /* fill: #E94D27;
    fill-opacity: .15; */
}
.legendLeft2 {
    display: block;
    width: 10px;
    height: 3px;
    margin-right: 5px;
    background-color: #fc3557 !important;
}
.pathLeft3 {
    stroke: #c7b700 !important;
    stroke-width: 3px;
    /* stroke-dasharray: 10; */
    /* fill: #C84297;
    fill-opacity: .15; */
}
.legendLeft3 {
    display: block;
    width: 10px;
    height: 2px;
    margin-right: 5px;
    background-color: #c7b700 !important;
}
.pathLeft4 {
    stroke: #ff8efc !important;
    /* stroke: #015baf !important; */
    stroke-width: 2px;
    fill: none;
}
.legendLeft4 {
    display: block;
    width: 10px;
    height: 2px;
    margin-right: 5px;
    background-color: #ff8efc !important;
}
.pathLeft5 {
    stroke: #c76800 !important;
    stroke-width: 2px;
    fill: none;
}
.legendLeft5 {
    display: block;
    width: 10px;
    height: 2px;
    margin-right: 5px;
    background-color: #c76800 !important;
}
.pathLeft6 {
    stroke: #67b7dd !important;
    stroke-width: 2px;
    fill: none;
}
.legendLeft6 {
    display: block;
    width: 10px;
    height: 2px;
    margin-right: 5px;
    background-color: #67b7dd !important;
}
.pathLeft7 {
    stroke: #a4142a !important;
    stroke-width: 2px;
    fill: none;
}
.legendLeft7 {
    display: block;
    width: 10px;
    height: 2px;
    margin-right: 5px;
    background-color: #a4142a !important;
}
.pathLeft8 {
    stroke: #95d688 !important;
    stroke-width: 2px;
    fill: none;
}
.legendLeft8 {
    display: block;
    width: 10px;
    height: 2px;
    margin-right: 5px;
    background-color: #95d688 !important;
}
.pathLeft9 {
    stroke: #8f2184 !important;
    stroke-width: 2px;
    fill: none;
}
.legendLeft9 {
    display: block;
    width: 10px;
    height: 2px;
    margin-right: 5px;
    background-color: #8f2184 !important;
}


/* #B54D6A, #583C69, #5B7EA3, #426755, #87C68D, #F7A74C, */
/* #D08D1F, #4176C6, #338649, #984E97, #D73A5F, #7D2A63 */

.pathRight0 {
    /* stroke: #015baf !important; */
    stroke: #E0B0E1 !important;
    stroke-width: 1px;
    /* stroke-dasharray: 5; */
    /* fill: #E0B0E1;
    fill-opacity: .10; */
    fill: none;
}
.legendRight0 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #E0B0E1 !important;
}
.pathRight1 {
    stroke: #89CCF4 !important;
    /* stroke-dasharray: 5; */
    stroke-width: 1px;
    /* fill: #89CCF4;
    fill-opacity: .10; */
    fill: none;
}
.legendRight1 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #89CCF4 !important;
}
.pathRight2 {
    /* stroke: #ff8efc !important; */
    stroke: #5FC04D !important;
    stroke-width: 1px;
    /* fill: #5FC04D;
    fill-opacity: .10; */
    fill: none;
}
.legendRight2 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #5FC04D !important;
}
.pathRight3 {
    stroke: #FF5917 !important;
    stroke-width: 1px;
    /* fill: #FF5917;
    fill-opacity: .10; */
    fill: none;
}
.legendRight3 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #FF5917 !important;
}
.pathRight4 {
    stroke: #28287C !important;
    stroke-width: 1px;
    /* fill: #28287C;
    fill-opacity: .10; */
    fill: none;
}
.legendRight4 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #28287C !important;
}

/* #D44E44, #83255B */
/* #5EAB35, #136974, #D96E1E */
.pathRight5 {
    stroke: #E0B0E1 !important;
    stroke-dasharray: 5;
    stroke-width: 1px;
    /* fill: #E0B0E1;
    fill-opacity: .10; */
    fill: none;
}
.legendRight5 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #E0B0E1 !important;
}
.pathRight6 {
    stroke: #89CCF4 !important;
    stroke-dasharray: 5;
    stroke-width: 1px;
    /* fill: #89CCF4;
    fill-opacity: .10; */
    fill: none;
}
.legendRight6 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #89CCF4 !important;
}
.pathRight7 {
    stroke: #FF5917 !important;
    stroke-dasharray: 5;
    stroke-width: 1px;
    /* fill: #FF5917;
    fill-opacity: .10; */
    fill: none;
}
.legendRight7 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #FF5917 !important;
}
.pathRight8 {
    stroke: #28287C !important;
    stroke-dasharray: 5;
    stroke-width: 1px;
    /* fill: #28287C;
    fill-opacity: .10; */
    fill: none;
}
.legendRight8 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #28287C !important;
}
.pathRight9 {
    stroke: #1B1B60 !important;
    stroke-dasharray: 5;
    stroke-width: 1px;
    /* fill: #1B1B60;
    fill-opacity: .10; */
    fill: none;
}
.legendRight9 {
    display: block;
    width: 10px;
    height: 1px;
    margin-right: 5px;
    background-color: #1B1B60 !important;
}

.x-axis {
    position: absolute;
    bottom: 0;
    height: 20px;
    left: 20px;
    right: 0;
    display: flex;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
}
.x-axis > div::after {
    margin-top: 4px;
    display: inline-block;
    content: attr(data-value);
    color: 82e0aa;
}

.y-axis-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 37px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
}
.y-axis-left > div::after {
    margin-right: 4px;
    content: attr(data-value);
    color: #015baf;
    display: inline-block;
}

.y-axis-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
}
.y-axis-right> div::after {
    margin-right: 4px;
    content: attr(data-value);
    color: #E0B0E1;
    display: inline-block;
}

.marker {
    stroke: #ff0000;
    stroke-width: 1px;
    fill: none;
}
.markers {
    stroke: #57575791;
    stroke-width: 1px;
    stroke-dasharray: 5;
    fill: none;
}

.mouseHover {
    font-family: Arial, Helvetica, sans-serif;
    fill: #333333;
    stroke: #333333;
    stroke-width: 0.3px;
}
.mouseHoverDarkmode {
    font-family: Arial, Helvetica, sans-serif;
    fill: #ffffff;
    stroke: #ffffff;
    stroke-width: 0.3px;
}
.mouseHoverBox {
    fill: #7e7373;
    stroke: #fff;
    stroke-width: 1px;
    fill-opacity: 0.7;
}
.mouseLine {
    stroke: #7090aa;
    stroke-width: 1px;
    stroke-dasharray: 5;
    fill: none;
}

.pointerCursor {
    cursor: pointer;
    pointer-events: inherit;
}

.scrolledDown{
    transform:translateY(-100%); transition: all 0.3s ease-in-out;
}
.scrolledUp{
    transform:translateY(0); transition: all 0.3s ease-in-out;
}